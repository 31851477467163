
import { endpoints } from "../../constants/endpoints";
import { getRequest } from "../../helpers/api";
import { httpErrorHandler, successNotiMessage } from "../../helpers/handler";

export const depositService = {
    store: async (dispatch) => {
        const result = await getRequest(endpoints.referral, null, dispatch);
        await httpErrorHandler(result, dispatch);

        if(result.status === 200) {
            successNotiMessage(dispatch, result);
        }
        
        return result;
    },

    index: async (dispatch) => {
        const result = await getRequest(endpoints.transaction, null, dispatch);
        await httpErrorHandler(result, dispatch);
        return result;
    },

    show: async (dispatch, id) => {
        const result = await getRequest(`${endpoints.transaction}/${id}`, null, dispatch);
        await httpErrorHandler(result, dispatch);
        return result;
    }
}