import { AppToolbar } from "../../../shares/AppToolbar"
import { AppMenu } from "../../../shares/AppMenu";
import { Card } from 'primereact/card';
import { KYCStatusMessage } from "../../../shares/messages/KYCStatusMessage";
import { useDispatch } from "react-redux"
import { useCallback, useEffect, useState } from "react";
import { dashboardService } from "../dashboardServices"
import numeral from "numeral";
import moment from "moment";

export const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(null);
    const [depostShow, setDepostShow] = useState(false);
    const [rioShow, setRoiShow] = useState(false);
    const [thisMonthShow, setThisMonthShow] = useState(false);
    const [commissionShow, setcommissionShow] = useState(false);

    const dispatch = useDispatch();

    const daysArray = [];
    const endOfOctober2024 = moment("2024-10").endOf('month');

    for (let i = 0; i < 6; i++) {
        daysArray.push(endOfOctober2024.clone().subtract(i, 'days').format('DD'));
    }

    const initLoading = useCallback(async () => {
        setLoading(true);
        const result = await dashboardService.index(dispatch);
        if (result?.status === 200) {
            setCount(result.data);
        }
        setLoading(false);
    }, [dispatch]);

    useEffect(() => { 
        initLoading();
    }, [initLoading]);

    return (
        <div className="app-wrapper">
            <AppMenu />

            <div className="app-content">
                <div className="grid">
                    <AppToolbar />
                </div>

                <div className="grid">
                    <div className="col-12">
                        <KYCStatusMessage />
                    </div>
                </div>

                {(
                    <div className="grid">
                        <div className="col-12" style={{padding: "20px"}}>
                            <p style={{ color: "#fdf7f7"}}> 
                                ယခုလအတွက် ရင်းနှီးမြုပ်နှံမှုဆိုင်ရာအကျိုးအမြတ် ပြန်လည်ခွဲ၀ေပေးသည့်ရက်များ - 
                                <code className="ml-1">
                                    {`${moment().format("MMMM")} ${daysArray.reverse().toString()}`}
                                </code>.
                            </p>
                        </div>
                        
                        <div className="col-12 md:col-3">
                            <Card className="p-shadow-3 bg-blue-600">
                                <div className="relative">
                                    <i 
                                        className={`pi ${depostShow ? 'pi-eye-slash' : 'pi-eye'}`} 
                                        style={{ fontSize: '1.5rem', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}
                                        onClick={() => setDepostShow(!depostShow)}
                                    />
                                </div>
                        
                                 <div className="flex align-items-center gap-2">
                                    <i className="pi pi-wallet text-3xl text-white" />
                        
                                    {!loading && count && depostShow ? (
                                        <span className="text-lg font-bold">{numeral(count.total_deposit_amount).format("0,0")}</span>
                                    ) : (
                                        <span className="text-lg font-bold"> *** </span>
                                    )}
                                </div>
                        
                                <span className="block mt-2 text-sm"> ရင်းနှီးမြုပ်နှံမှုစုစုပေါင်း (EMU)</span>
                            </Card>
                        </div>

                        <div className="col-12 md:col-3">
                            <Card className="p-shadow-3 bg-green-600">
                                <div className="relative">
                                    <i 
                                        className={`pi ${rioShow ? 'pi-eye-slash' : 'pi-eye'}`} 
                                        style={{ fontSize: '1.5rem', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}
                                        onClick={() => setRoiShow(!rioShow)}
                                    />
                                </div>

                                <div className="flex align-items-center gap-2">
                                    <i className="pi pi-wallet text-3xl text-white-500" />

                                    {!loading && count && rioShow ? (
                                        <span className="text-lg font-bold">{numeral(count.total_repayment).format("0,0")}</span>
                                    ) : (
                                        <span className="text-lg font-bold"> *** </span>
                                    )}
                                </div>

                                <span className="block mt-2 text-sm"> အကျိုးအမြတ်စုစုပေါင်း (EMU)</span>
                            </Card>
                        </div>

                        <div className="col-12 md:col-3">
                            <Card className="p-shadow-3 bg-yellow-500">
                                <div className="relative">
                                    <i 
                                        className={`pi ${thisMonthShow ? 'pi-eye-slash' : 'pi-eye'}`} 
                                        style={{ fontSize: '1.5rem', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}
                                        onClick={() => setThisMonthShow(!thisMonthShow)}
                                    />
                                </div>

                                <div className="flex align-items-center gap-2">
                                    <i className="pi pi-wallet text-3xl text-white-500" />

                                    {!loading && count && thisMonthShow ? (
                                        <span className="text-lg font-bold">{numeral(count.this_month_repayment).format("0,0")}</span>
                                    ) : (
                                        <span className="text-lg font-bold"> *** </span>
                                    )}
                                </div>

                                <span className="block mt-2 text-sm">
                                    ယခုလရရှိမည့် အကျိုးအမြတ် ({moment().format("MMM, YYYY")}) (EMU)
                                </span>
                            </Card>
                        </div>

                        <div className="col-12 md:col-3">
                            <Card className="p-shadow-3 bg-orange-500">
                                <div className="relative">
                                    <i 
                                        className={`pi ${commissionShow ? 'pi-eye-slash' : 'pi-eye'}`} 
                                        style={{ fontSize: '1.5rem', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}
                                        onClick={() => setcommissionShow(!commissionShow)}
                                    />
                                </div>

                                <div className="flex align-items-center gap-2">
                                    <i className="pi pi-wallet text-3xl text-white-500" />

                                    {!loading && count && commissionShow ? (
                                        <span className="text-lg font-bold">{numeral(count.commission_amount).format("0,0")}</span>
                                    ) : (
                                        <span className="text-lg font-bold"> *** </span>
                                    )}
                                </div>

                                <span className="block mt-2 text-sm">
                                    ကော်မတ်ရှင်အကျိုးအမြတ် (EMU)
                                </span>
                            </Card>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}