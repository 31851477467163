import { useCallback, useEffect, useState } from "react";
import { AppMenu } from "../../../shares/AppMenu";
import { AppToolbar } from "../../../shares/AppToolbar";
import { KYCStatusMessage } from "../../../shares/messages/KYCStatusMessage";
import { useDispatch } from "react-redux";
import { DataTable } from "primereact/datatable";
import { depositPayloaad } from "../depositPayload";
import { Column } from "primereact/column";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import numeral from "numeral";
import { depositService } from "../depositService";
import { Badge } from "primereact/badge";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { endpoints } from "../../../constants/endpoints";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";

export const DepositDetial = () => {
    const [loading, setLoading] = useState(false);
    const [transaction, setTransaction] = useState(null);
    const [repayment, setRepayment] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initLoading = useCallback( async () => {
        setLoading(true);
        const result = await depositService.show(dispatch, params.id);
        
        if(result.status === 200) {
            setTransaction(result.data);
            setRepayment(result.data.repayments);
        }

        setLoading(false);
    }, [params]);

    useEffect(() => {
        initLoading();
    },[initLoading]);

    return (
        <div className="app-wrapper">
            <AppMenu />
        
            <div className="app-content">
                <div className="grid">
                    <AppToolbar />
                </div>
        
                <div className="grid">
                    <div className="col-12">
                        <KYCStatusMessage />
                    </div>
                </div>

                <div className="grid">
                    {transaction && (
                        <div className="col-12">
                            <Card
                                title="ရင်းနှီးမြုပ်နှံမှုပြုလုပ်ထားသည့် ပြေစာအချက်အလက်အသေးစိတ်"
                                subTitle={`#${transaction.id} - ပြေစာအမျိုးအစား - ${transaction.transaction_type}`}
                            >
                                <div className="grid">
                                    <div className="col-12 p-3">
                                        <div className="flex flex-row justify-content-end align-items-start mt-3">
                                            <Button severity="success" onClick={() => setOpenDialog(true)}> ပြေစာဓါတ်ပုံပြန်လည်ကြည့်ရန် </Button>
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-4 lg:col-4 p-3">
                                        <h3> ရင်းနှီးမြုပ်နှံမှုလက်ခံသူအချက်လက် </h3>
                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> အကောင့်နာမည် </span>
                                            <span> {transaction.merchant_account_name}</span>
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> ဘဏ်အကောင့်နံပါတ် </span>
                                            <span> {transaction.merchant_account_number}</span>
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> ဘဏ်အမျိုးအစား </span>
                                            <span> {transaction.bank_type}</span>
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-4 lg:col-4 p-3">
                                        <h3> ရင်းနှီးမြုပ်နှံသူအချက်အလက် </h3>
                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> အကောင့်နာမည် </span>
                                            <span> {transaction.sender_account_name}</span>
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> ဘဏ်အကောင့်နံပါတ် </span>
                                            <span> {transaction.sender_account_number}</span>
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> ဘဏ်အမျိုးအစား </span>
                                            <span> {transaction.bank_type}</span>
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-4 lg:col-4 p-3">
                                        <h3> ရင်းနှီးမြုပ်နှံမှုဆိုင်ရာအချက်အလက် </h3>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> အမျိုးအစား </span>
                                            <span> {transaction.package_name}</span>
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> ရင်းနှီးမြုပ်နှံမှုပမာဏ  </span>
                                            <span> {numeral(transaction.package_deposit_amount).format('0,0')} (EMU) </span>
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> ကြာချိန် </span>
                                            <span> {transaction.package_duration} Months </span>
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> အကျိုးအမြတ်ရာခိုင်နှုန်း </span>
                                            <span> {transaction.package_roi_rate} % </span>
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> လစဥ်အကျိုးအမြတ် (အများဆုံး) </span>
                                            <span> { numeral(transaction.package_deposit_amount * transaction.package_roi_rate / 100).format('0,0')} (EMU) </span>
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> နေ့စဥ်အကျိုးအမြတ် </span>
                                            <span> { numeral(transaction.package_deposit_amount * transaction.package_roi_rate / 100 / 30).format('0,0')} (EMU) </span>
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> ရင်းနှီးမြုပ်နှံမှုစတင်သည့်နေ့ </span>
                                            <span> {moment(transaction.created_at).format('DD/MM/YYYY')} </span>
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> ရင်းနှီးမြုပ်နှံမှုအတည်ပြုနေ့ </span>
                                            {transaction.updated_at !== null ? (
                                                <span> {moment(transaction.updated_at).format('DD/MM/YYYY')} </span>
                                            ) :  <Badge severity={"warning"} value={"Waiting"} />
                                            }
                                        </div>

                                        <div className="flex flex-row justify-content-between align-items-start mt-3">
                                            <span> ရင်းနှီးမြုပ်နှံမှုသက်တမ်းကုန်ဆုံးမည့်နေ့ </span>
                                            {transaction.expired_at !== null ? (
                                                <span> {moment(transaction.expired_at).format('DD/MM/YYYY')} </span>
                                            ) :  <Badge severity={"warning"} value={"Waiting"} />
                                            }
                                        </div>

                                    </div>

                                    <div className="col-12 p-3">
                                        <h3> လစဥ်ရရှိမည့် အကျိုးအမြတ်ပြဇယား </h3>
                                        <DataTable 
                                            dataKey="id"
                                            size="normal"
                                            value={repayment}
                                            loading={loading}
                                        >
                                            {depositPayloaad.repaymentColumns.map((column, index) => {
                                                return (
                                                    <Column
                                                        style={{ minWidth: column.width }}
                                                        field={column.field}
                                                        header={column.header}
                                                        key={`repayment_id_${index}`}
                                                        body={(value) => {
                                                            switch (column.field) {
                                                                case "amount":
                                                                    return(
                                                                        <span> {numeral(value[column.field]).format('0,0')} (EMU) </span>
                                                                    )
                                                                case "date":
                                                                    return (
                                                                        <span> {moment(value[column.field]).format('DD-MM-YYYY')} </span>
                                                                    );
                                                                case "oneday_amount":
                                                                    return (
                                                                        <span> {numeral(value[column.field]).format('0,0')} (EMU) </span>
                                                                    );
                                                                case "total_amount":
                                                                    return(
                                                                        <span> {numeral(value[column.field]).format('0,0')} (EMU) </span>
                                                                    )
                                                                // case "duration":
                                                                //     return (
                                                                //         <span> {moment(value['expired_at']).diff(moment(value['created_at']), 'month')} Months </span>
                                                                //     );
                                                                case "created_at":
                                                                    return (
                                                                        <span>
                                                                            {moment(value[column.field]).format("DD/MM/YYYY")}
                                                                        </span>
                                                                    );
                                                                case "updated_at":
                                                                    return (
                                                                        <span>
                                                                            {moment(value[column.field]).format("DD/MM/YYYY")}
                                                                        </span>
                                                                    );
                                                                case "expired_at":
                                                                    return (
                                                                        <span>
                                                                            { value[column.field] !== null ? moment(value[column.field]).format("DD/MM/YYYY") : "---" }
                                                                            
                                                                        </span>
                                                                    );
                                                                case "status":
                                                                    return(
                                                                        <Badge value={value[column.field]} severity={value[column.field] === 'AVAILABLE_WITHDRAW' ? "success" : "danger"} />
                                                                    )
                                                                default:
                                                                    return value[column.field];
                                                            }
                                                        }}
                                                    />
                                                )
                                            })}
                                        </DataTable>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )}
                </div>
            </div>

            {!loading && openDialog && (
                <Dialog
                    header="Transcation Screenshoot"
                    visible={openDialog}
                    style={{ width: '50vw' }}
                    onHide={() => { if (!openDialog) return; setOpenDialog(false); }}
                >
                    <p className="m-0">
                        <Image
                            width="100%"
                            src={`${endpoints.image}/${transaction.transaction_screenshoot}`}
                        />
                    </p>
                </Dialog>
            )}
        </div>
    )
}