import { AppMenu } from "../../../shares/AppMenu"
import { AppToolbar } from "../../../shares/AppToolbar"
import { KYCStatusMessage } from "../../../shares/messages/KYCStatusMessage"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KYCSTATUS } from "../../../constants/config";
import { Notification } from "../../../shares/Notification";
import { referralService } from "../referralService";
import { referralPayload } from "../referralPayload";
import { Button } from "primereact/button";
import { useParams } from "react-router-dom";
import { Badge } from "primereact/badge";
import moment from "moment";

export const ReferralPartnerList = () => {

    const [loading, setLoading] = useState(false);
    const [partners, setPartner] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
   
    const { profile } = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const param = useParams();

    const initloading = useCallback(async () => {
        setLoading(true);
        const result = await referralService.partnerIndex(dispatch, param.id);
        if(result.status === 200) {
            setPartner(result.data);
        }
        setLoading(false);
    }, [dispatch]);

    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                <h5>Orders for {data.name}</h5>
                <DataTable value={data.orders}>
                    <Column field="id" header="Id" sortable></Column>
                    <Column field="customer" header="Customer" sortable></Column>
                    <Column field="date" header="Date" sortable></Column>
                    <Column field="amount" header="Amount"></Column>
                    <Column field="status" header="Status"></Column>
                    <Column headerStyle={{ width: '4rem' }}></Column>
                </DataTable>
            </div>
        );
    };

    const allowExpansion = (rowData) => {
        return rowData.orders.length > 0;
    };

    useEffect(() => {
        initloading();
    }, [initloading]);

    return (
        <div className="app-wrapper">
            <AppMenu />

            <div className="app-content">
                <div className="grid">
                    <AppToolbar />
                </div>

                <div className="grid">
                    <div className="col-12">
                        <KYCStatusMessage />
                    </div>
                </div>

                {profile && profile.kyc_status === KYCSTATUS.FULL_KYC && profile.status === "ACTIVE" && (
                    <div className="grid">
                        <h3 style={{color: "#fff", paddingLeft: "10px", paddingRight: "10px"}}> Your Referral Partner </h3>
                        <div className="col-12">
                            <DataTable
                                dataKey="id"
                                size="normal"
                                value={partners ? partners : []}
                                loading={loading}
                                onRowToggle={(e) => setExpandedRows(e.data)}
                                rowExpansionTemplate={rowExpansionTemplate}
                            >
                                {referralPayload.parterColumns.map((column, index) => {
                                    return (
                                        <Column
                                            style={{ minWidth: "250px" }}
                                            field={column.field}
                                            header={column.header}
                                            key={`partner_id_${index}`}
                                            expander={allowExpansion}
                                            body={(value) => {
                                                switch (column.field) {
                                                    case "name":
                                                        return (
                                                            <span> {`${value["first_name"]} ${value["last_name"]}` } </span>
                                                        )
                                                    case "kyc_status":
                                                        return <Badge value={value[column.field]} severity={value[column.field] === "FULL_KYC" ? 'success' : "warning"} />;
                                                    case "status":
                                                            return <Badge value={value[column.field]} severity={value[column.field] === "PENDING" ? 'warning' : value[column.field] === 'BLOCK' ? "danger" : "success"} />;
                                                    case "created_at":
                                                        return (
                                                            <span> {moment(value[column.field]).format("DD-MM-YYYY hh:mm:ss A")} </span>
                                                        )
                                                    case "updated_at":
                                                        return (
                                                            <span> {moment(value[column.field]).format("DD-MM-YYYY hh:mm:ss A")} </span>
                                                        )
                                                    default:
                                                        return value[column.field];
                                                }
                                            }}
                                        />
                                    )
                                })}
                            </DataTable>
                        </div>
                    </div>
                )}
            </div>
            <Notification />
        </div>
    )
}
