export const depositPayloaad = {
    createTransaction: {
        merchant_account_id: '',
        package_id: '',
        transaction_screenshoot: '',
        package_deposit_amount: '',
        sender_account_id: "",
        bank_type: "",
    },

    update: {
        partner_id: "",
        account_name: "",
        account_number: "",
        bank_type: "",
        bank_type_label: "",
        branch: "",
        branch_address: "",
        status: ""
    },

    columns: [
        { field: "#", header: "#", width: "50px" },
        { field: 'id', header: "ပြေစာအမှတ်", width: "100px" },
        { field: 'bank_type', header: "ဘဏ်အမျိုးအစား", width: "200px" },
        { field: 'merchant_account_name', header: "လက်ခံသူနာမည်", width: "250px" },
        { field: 'merchant_account_number', header: "လက်ခံသူဘဏ်နံပါတ်", width: "250px" },
        { field: 'sender_account_name', header: "လွှဲပြောင်းသူနာမည်", width: "250px" },
        { field: 'sender_account_number', header: "လွှဲပြောင်းသူဘဏ်နံပါတ်", width: "250px" },
        { field: 'package_name', header: "ရင်းနှီးမြုပ်နှံမှုအမျိုးအစား", width: "200px" },
        { field: 'package_duration', header: 'ကြာချိန်', width: "100px" },
        { field: 'package_roi_rate', header: 'ရာခိုင်နှုန်း', width: "150px" },
        { field: 'package_deposit_amount', header: "ပမာဏ", width: "200px" },
        { field: 'created_at', header: 'စတင်သည့်ရက်စွဲ', width: "200px" },
        { field: 'updated_at', header: "အတည်ပြုရက်စွဲ", width: "200px" },
        { field: 'expired_at', header: 'သက်တမ်းကုန်ဆုံးမည့်ရက်စွဲ', width: "250px" },
        { field: 'transaction_type', header: 'လွှဲပြောင်းမှုအမျိုးအစား', width: "200px" },
        { field: 'status', header: "အခြေအနေ", width: "200px" }
    ],
    repaymentColumns: [
        { field: "id", header: "ပြေစာအမှတ်", width: "100px" },
        { field: "amount", header: "အကျိုးအမြတ် (စုစုပေါင်း)", width: "220px" },
        { field: "count_days", header: "ရက်တွက်မှု", width: "120px" },
        { field: "date", header: "လွဲပြောင်းမည့်နေ့", width: "200px" },
        { field: "oneday_amount", header: "နေ့စဥ်အကျိုးအမြတ်", width: "200px" },
        { field: "total_amount", header: "ပမာဏ", width: "150px" },
        { field: "total_days", header: "စုစုပေါင်းရက်", width: "150px" },
        { field: "created_at", header: "စတင်သည့်နေ့", width: "150px" },
        { field: "updated_at", header: "အတည်ပြုနေ့", width: "150px" },
        { field: "status", header: "အခြေအနေ", width: "300px" }
    ]
}