import { useSelector } from "react-redux";

export const ValidationMessage = ({field}) => {

    const state = useSelector(state => state.share);
    const { errors } = state;

    return (
        <>
            { errors && errors[field] && (
                <span style={{width: "100%", marginTop: "5px", color: "red", fontSize: "small"}}> { errors[field][0] } </span>
            )}
        </>
    )
}