export const referralPayload = {
    columns: [
        { field: 'link', header: 'Referral Link' },
        { field: 'count', header: 'Count' },
        { field: 'commission', header: 'Commission (%)' },
        { field: 'action', header: "Action" }
    ],

    parterColumns: [
        { field: "id", header: "ID", width: "100px" },
        { field: "name", header: "Name", width: "200px" },
        { field: "kyc_status", header: "KYC Status", width: "150px" },
        { field: "status", header: "Status", width: "150px" },
        { field: "created_at", header: "Created At", width: "150px" },
        { field: "updated_at", header: "Updated At", width: "150px" },
    ]
}