import { NotFoundPage } from "../../pages/NotFoundPage";
import { paths } from "../../constants/paths";
import { ReferralList } from "./view/ReferralList";
import { ReferralPartnerList } from "./view/ReferralPartnerList";

export const referralRoutes = [
    {
        path: paths.referral,
        element: <ReferralList />,
        errorElement: <NotFoundPage />
    },
    {
        path: `${paths.referral}/partner/:id`,
        element: <ReferralPartnerList />,
        errorElement: <NotFoundPage />
    }
];