import { useCallback, useEffect, useState } from "react";
import { AppMenu } from "../../../shares/AppMenu";
import { AppToolbar } from "../../../shares/AppToolbar";
import { KYCStatusMessage } from "../../../shares/messages/KYCStatusMessage";
import { useDispatch } from "react-redux";
import { CreateDeposit } from "../entry/CreateDeposit";
import { DataTable } from "primereact/datatable";
import { depositPayloaad } from "../depositPayload";
import { Column } from "primereact/column";
import { paths } from "../../../constants/paths";
import { useNavigate } from "react-router-dom";
import { depositService } from "../depositService";
import { Badge } from "primereact/badge";
import moment from "moment";
import numeral from "numeral";
import { Card } from "primereact/card";

export const DepositList = () => {

    const [loading, setLoading] = useState(false);
    const [transaction, setTransaction] = useState([]);
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [depositShow, setDepostShow] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const initializeData = useCallback(async () => {
        setLoading(true);
        const result = await depositService.index(dispatch);
        if(result.status === 200) {
            setTotalDeposit(result.data.total_deposit);
            setTransaction(result.data.transactions);
        }
        setLoading(false);
    }, [dispatch]);

    useEffect(() => {
        initializeData();
    }, [initializeData]);

    return (
        <div className="app-wrapper">
            <AppMenu />

            <div className="app-content">
                <div className="grid">
                    <AppToolbar />
                </div>

                <div className="grid">
                    <div className="col-12">
                        <KYCStatusMessage />
                    </div>
                </div>

                <CreateDeposit />

                <div className="grid">
                    <div className="col-12 md:col-3">
                        <Card className="p-shadow-3 bg-blue-600">
                            <div className="relative">
                                <i 
                                    className={`pi ${depositShow ? 'pi-eye-slash' : 'pi-eye'}`} 
                                    style={{ fontSize: '1.5rem', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}
                                    onClick={() => setDepostShow(!depositShow)}
                                />
                            </div>
                        
                            <div className="flex align-items-center gap-2">
                                <i className="pi pi-wallet text-3xl text-white" />
                        
                                {!loading && depositShow ? (
                                    <span className="text-lg font-bold">{numeral(totalDeposit).format("0,0")}</span>
                                ) : (
                                    <span className="text-lg font-bold"> *** </span>
                                )}
                            </div>
                        
                            <span className="block mt-2 text-sm"> ရင်းနှီးမြုပ်နှံမှုငွေစုစုပေါင်း (EMU) </span>
                        </Card>
                    </div>

                    <div className="col-12">
                        <h3 style={{color: "#fff"}}> လွှဲပြောင်းမှုမှတ်တမ််းများ </h3>
                        <DataTable
                            dataKey="id"
                            size="normal"
                            value={transaction}
                            loading={loading}
                        >
                            {depositPayloaad.columns.map((column, index) => {
                                return (
                                    <Column
                                        style={{ minWidth: column.width }}
                                        field={column.field}
                                        header={column.header}
                                        key={`deposit_id_${index}`}
                                        body={(value, options) => {
                                            switch (column.field) {
                                                case "#":
                                                    return <span> {options.rowIndex + 1} </span>
                                                case "id":
                                                    return(
                                                        <span className="underline" style={{cursor: "pointer"}} onClick={() => navigate(`${paths.deposit}/${value['id']}`)}> 
                                                            {value[column.field]}
                                                        </span>
                                                    )
                                                case "package_deposit_amount":
                                                    return(
                                                        <span> {numeral(value[column.field]).format('0,0')} (EMU) </span>
                                                    )
                                                case "package_duration":
                                                    return (
                                                        <span> {value[column.field]} Months </span>
                                                    );
                                                case "roi_amount":
                                                    return (
                                                        <span> {numeral(value[column.field]).format('0,0')} (EMU) </span>
                                                    );
                                                case "duration":
                                                    return (
                                                        <span> {moment(value['expired_at']).diff(moment(value['created_at']), 'month')} Months </span>
                                                    );
                                                case "transaction_type":
                                                    return <Badge value={value[column.field]} severity="warning" />
                                                case "created_at":
                                                    return (
                                                        <span>
                                                            {moment(value[column.field]).format("DD/MM/YYYY")}
                                                        </span>
                                                    );
                                                case "updated_at":
                                                    return (
                                                        <span>
                                                            {moment(value[column.field]).format("DD/MM/YYYY")}
                                                        </span>
                                                    );
                                                case "expired_at":
                                                    return (
                                                        <span>
                                                            { value[column.field] !== null ? moment(value[column.field]).format("DD/MM/YYYY") : "---" }
                                                            
                                                        </span>
                                                    );
                                                case "status":
                                                    return(
                                                        <Badge value={value[column.field]} severity={value[column.field] === 'DEPOSIT_PENDING' ? "warning" : "success"} />
                                                    )
                                                default:
                                                    return value[column.field];
                                            }
                                        }}
                                    />
                                )
                            })}
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}