
export const paths = {
    profile: "/profile",
    dashboard: "/dashboard",
    bank: "/bank-account",
    login: '/',
    forgotPassword: '/forgot-password',
    verifyOtp: '/verify-otp',
    resetPassword: '/reset-password',
    referral: '/referral',
    deposit: "/deposit",
    transaction: "/transaction",
    agent: '/agent',
    referralPartner: "referral-partner"
}