import { Login } from "./entry/Login";
import { NotFoundPage } from "../../pages/NotFoundPage";
import { paths } from "../../constants/paths";
import { AccountProfile } from "./view/AccountProfile";
import { Register } from "./entry/Register";
import { ForgotPassword } from "./entry/ForgotPassword"
import { VerifyOtp } from "./entry/VerifyOtp";
import { ResetPassword } from "./entry/ResetPassword";

export const authRoutes = [
    {
        path: "/",
        element: <Login />,
        errorElement: <NotFoundPage />
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />,
        errorElement: <NotFoundPage />
    },
    {
        path: "/verify-otp",
        element: <VerifyOtp />,
        errorElement: <NotFoundPage />
    },
    {
        path: "/reset-password",
        element: <ResetPassword />,
        errorElement: <NotFoundPage />
    },
    {
        path: "/register",
        element: <Register />,
        errorElement: <NotFoundPage />
    },
    {
        path: paths.profile,
        element: <AccountProfile />
    }
];