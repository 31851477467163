import { useEffect, useState } from "react"
import { referralService } from "../referralService";
import { useDispatch, useSelector } from "react-redux";
import { setErrors, setNotification } from "../../../shares/shareSlice";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../constants/paths";
import { KYCSTATUS } from "../../../constants/config";
import { InputText } from "primereact/inputtext";
import { payloadHandler } from "../../../helpers/handler";
import { ValidationMessage } from "../../../shares/ValidationMessage";

export const CreateReferral = () => {

    const [loading, setLoading] = useState(false);
    const [referral, setReferral] = useState(null);
    const [payload, setPayload] = useState({
        percentage: ""
    });

    const { profile } = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const generateReferral = async () => {
        setLoading(true);

        const result = await referralService.commissionReferralStore(payload, dispatch);

        if(result.status === 200) {
            setReferral(`partner.evanglobalmanagement.com/register?=${result.data.link}`);
            await referralService.index(dispatch);
        }
        
        setLoading(false);
    }

    const copyReferralLink = () => {
        const copyText = document.getElementById("referral-link").innerHTML;
        navigator.clipboard.writeText(copyText);
    }

    const DepositFirst = () => {
        return (
            <div className="w-full">
                <p>
                    First, you need to deposit at least <code> 10,000,000 MMK</code> and you can generate referral links.
                    Your deposit's ROI (Return on investment) rate is <code> {profile.roi}% </code> and commission referral percentage must be between
                    <code> 1% </code> and <code> {profile.roi}% </code>.
                </p>

                <Button
                    label="Deposit"
                    onClick={() => navigate(paths.deposit)}
                />
            </div>
        )
    }

    useEffect(() => {
        dispatch(setNotification(null));
        dispatch(setErrors(null));
    }, [dispatch]);

    return (
        <Card
            title="Generate Referral Link"
        >
            <div className="flex flex-column align-items-start justify-content-center">
                <p>
                    First, you need to deposit at least <code> 10,000,000 MMK</code> and you can generate referral links.
                    Your deposit's ROI (Return on investment) rate is <code> {profile.roi}% </code> and commission referral percentage must be between
                    <code> 1% </code> and <code> {profile.roi}% </code>.
                </p>

                {referral && (
                    <code onClick={() => copyReferralLink()}>
                        <span id="referral-link"> {referral} </span>
                        <Button icon="pi pi-copy" rounded text />
                    </code>
                )}

                {profile && profile.deposit.length > 0 && profile.kyc_status === KYCSTATUS.FULL_KYC && profile.status === "ACTIVE" && (
                    <div className="col-12 md:col-4 lg:col-4 mt-3 ml-2">
                        <div className="p-inputgroup flex-1">
                            <InputText
                                style={{
                                    border: "1px solid #959494",
                                    borderRadius: "5px"
                                }}
                                type="number"
                                placeholder="Enter Percentage Amount"
                                disabled={loading}
                                onChange={(e) => payloadHandler(payload, e.target.value, 'percentage', (updatePayload) => {
                                    setPayload(updatePayload);
                                })}
                            />
                        </div>
                        <ValidationMessage field={'percentage'} />
                    </div>
                )}

                <Button
                    size="small"
                    className="mt-3 ml-3"
                    label="Create"
                    icon="pi pi-share-alt"
                    severity="warning"
                    disabled={loading}
                    loading={loading}
                    onClick={() => generateReferral()}
                />
            </div>

            {profile && profile.deposit.length === 0 && (
                <Message
                    content={<DepositFirst />}
                    severity="warn"
                />
            )}
        </Card>
    )
}