import { useCallback, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { InputText } from 'primereact/inputtext';
import { payloadHandler } from "../../../helpers/handler";
import { authService } from "../authService";
import { useDispatch } from "react-redux";
import { Notification } from "../../../shares/Notification";
import { useNavigate } from "react-router-dom";
import LOGO from "../../../assets/images/egm_logo.png";
import { ValidationMessage } from "../../../shares/ValidationMessage";

export const Register = () => {

    const [loading, setLoading] = useState(false);
    const [referral, setReferral] = useState("");
    const [payload, setPayload] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        referral: ""
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitCreateAccount = async () => {
        setLoading(true);

        let updatePayload = {...payload};
        updatePayload.referral = referral;

        const result = await authService.register(updatePayload, dispatch);

        if (result.status === 200) {
            navigate('/');
        }

        setLoading(false);
    }

    const loadingData = useCallback(() => {
        const urlQueryValue = window.location.search.substring(1).split("=")[1];
        if (urlQueryValue) {
            setReferral(urlQueryValue);
        }
    }, []);

    useEffect(() => {
        loadingData();
    }, [loadingData]);

    return (
        <div className="gird">
            <div className="row">
                <div className="col-12 mt-3">
                    <div className="flex flex-column align-items-center justify-content-center">
                        <Image
                            width="100px"
                            height="100px"
                            src={LOGO}
                            title="Evan Global Management"
                            alt="Evan Global Management"
                        />
                        <span className="text-lg text-white"> Evan Global Management </span>

                        <div className="col-12 md:col-4 lg:col-4 mt-3 mb-3">
                            <Card
                                title="Create Partner Account"
                                subTitle={
                                    <span className="text-white text-sm font-light">
                                        We seek to build lasting partnerships underpinned by trust and credibility.
                                    </span>
                                }
                            >
                                <div className="p-inputgroup mt-3">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <InputText
                                        className="p-inputtext-sm"
                                        placeholder="First Name"
                                        disabled={loading}
                                        value={payload.first_name}
                                        onChange={(e) => payloadHandler(payload, e.target.value, 'first_name', (updatePayload) => {
                                            setPayload(updatePayload);
                                        })}
                                    />
                                </div>

                                <ValidationMessage field="first_name" />

                                <div className="p-inputgroup mt-3">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <InputText
                                        className="p-inputtext-sm"
                                        placeholder="Last Name"
                                        disabled={loading}
                                        value={payload.last_name}
                                        onChange={(e) => payloadHandler(payload, e.target.value, 'last_name', (updatePayload) => {
                                            setPayload(updatePayload);
                                        })}
                                    />
                                </div>

                                <ValidationMessage field="last_name" />

                                <div className="p-inputgroup mt-3">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-envelope"></i>
                                    </span>
                                    <InputText
                                        className="p-inputtext-sm"
                                        placeholder="Email"
                                        disabled={loading}
                                        value={payload.email}
                                        onChange={(e) => payloadHandler(payload, e.target.value, 'email', (updatePayload) => {
                                            setPayload(updatePayload);
                                        })}
                                    />
                                </div>

                                <ValidationMessage field="email" />

                                <div className="p-inputgroup mt-3">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-phone"></i>
                                    </span>
                                    <InputText
                                        className="p-inputtext-sm"
                                        placeholder="Phone"
                                        disabled={loading}
                                        value={payload.phone}
                                        onChange={(e) => payloadHandler(payload, e.target.value, 'phone', (updatePayload) => {
                                            setPayload(updatePayload);
                                        })}
                                    />
                                </div>

                                <ValidationMessage field="phone" />

                                <div className="p-inputgroup mt-3">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <InputText
                                        type="password"
                                        className="p-inputtext-sm"
                                        placeholder="Password"
                                        disabled={loading}
                                        value={payload.password}
                                        onChange={(e) => payloadHandler(payload, e.target.value, 'password', (updatePayload) => {
                                            setPayload(updatePayload);
                                        })}
                                    />
                                </div>

                                <ValidationMessage field="password" />

                                <div className="p-inputgroup mt-3">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <InputText
                                        type="password"
                                        className="p-inputtext-sm"
                                        placeholder="Confirm Password"
                                        disabled={loading}
                                        value={payload.password_confirmation}
                                        onChange={(e) => payloadHandler(payload, e.target.value, 'password_confirmation', (updatePayload) => {
                                            setPayload(updatePayload);
                                        })}
                                    />
                                </div>

                                <div className="p-inputgroup mt-3">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-share-alt"></i>
                                    </span>
                                    <InputText
                                        type="text"
                                        className="p-inputtext-sm"
                                        placeholder="Referral Code (Optional)"
                                        disabled={true}
                                        value={referral}
                                        onChange={(e) => payloadHandler(payload, e.target.value, 'referral', (updatePayload) => {
                                            setPayload(updatePayload);
                                        })}
                                    />
                                </div>

                                <ValidationMessage field="referral" />

                                <div className="p-inputgroup mt-3">
                                    <div className="w-full flex flex-row align-items-center justify-content-end">
                                        <Button
                                            loading={loading}
                                            disabled={loading}
                                            className="p-button-sm primary-btn"
                                            label="Create Account"
                                            onClick={() => submitCreateAccount()}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="col-12 md:col-4 lg:col-4">
                            <div className="flex flex-row align-items-center justify-content-center">
                                <span
                                    style={{ color: "#fff", "textDecoration": "underline", "cursor": "pointer" }}
                                    onClick={() => navigate("/")}
                                >
                                    LOGIN PARTNER ACCOUNT
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Notification />
        </div>
    );
}