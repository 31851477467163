export const bankAccountPayload = {
    create: {
        partner_id: "",
        account_name: "", 
        account_number: "", 
        bank_type: "", 
        bank_type_label: "", 
        branch: "", 
        branch_address: "", 
    },
    update: {
        partner_id: "",
        account_name: "", 
        account_number: "", 
        bank_type: "", 
        bank_type_label: "", 
        branch: "", 
        branch_address: "", 
        status: ""
    },
    columns: [
        {field: 'account_number', header: 'ဘဏ်အကောင့်နံပါတ်'},
        {field: 'account_name', header: 'ကိုင်ဆောင်သူနာမည်'},
        {field: 'bank_type', header: 'အမျိုးအစား'},
        {field: 'branch', header: 'ဘဏ်ခွဲတည်နေရာ'},
        {field: 'branch_address', header: 'လိပ်စာ'}
    ]
}